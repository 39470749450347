import React from "react";
import PropTypes from "prop-types";

import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const SimpleAccordionItem = ({ accordionItem, id, animation }) => {
  return (
    <div className="accordion-item" data-aos={animation.accordion.default}>
      <h2 className="accordion-header" id={`flush-heading ${accordionItem.id}`}>
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#flush-collapse-${accordionItem.id}`}
          aria-expanded="false"
          aria-controls={`flush-collapse-${accordionItem.id}`}
        >
          {accordionItem.header}
        </button>
      </h2>

      <div
        id={`flush-collapse-${accordionItem.id}`}
        className="accordion-collapse collapse"
        aria-labelledby={`flush-heading${accordionItem.id}`}
        data-bs-parent={`#${id}`}
      >
        <div className="accordion-body" dangerouslySetInnerHTML={{ __html: accordionItem.body }} />

        {accordionItem.link && (
          <div className="accordion-body">
            {/* <div className="links"> */}
            <LinkSwitcher link={accordionItem.link} linkType="link-primary" />
            {/* </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

SimpleAccordionItem.propTypes = {
  id: PropTypes.string,
  accordionItem: PropTypes.instanceOf(Object),
  animation: PropTypes.instanceOf(Object),
};

export default SimpleAccordionItem;
