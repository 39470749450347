import React, { useCallback, useContext } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import StandardPersonioDetails from "./StandardPersonioDetailsView";

import TranslationContext from "../../../Context/Translation";

const StandardPersonio = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    jobs,
    // companies,
    // categories,
    ctaHeadline,
    ctaTeaser,
    backPageUrl,
    animation,
  },
}) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const renderDetailsView = useCallback(() => {
    return (
      <>
        <NavLink to={backPageUrl} className="personio-details-return">
          {activeTranslation.twoLetterIsoCode === "de" ? "Zurück" : "Go Back"}
        </NavLink>

        <div className="mt-small">
          <StandardPersonioDetails
            job={jobs[0]}
            ctaHeadline={ctaHeadline}
            ctaTeaser={ctaTeaser}
            translations={{
              office: activeTranslation.twoLetterIsoCode === "de" ? "Standort" : "Location",
              subcompany:
                activeTranslation.twoLetterIsoCode === "de" ? "Gesellschaft" : "Subcompany",
              recruitingCategory:
                activeTranslation.twoLetterIsoCode === "de" ? "Art der Beschäftigung" : "Job Type",
              schedule: activeTranslation.twoLetterIsoCode === "de" ? "Arbeitszeit" : "Schedule",
              apply: activeTranslation.twoLetterIsoCode === "de" ? "Jetzt Bewerben" : "Apply Now",
            }}
          />
        </div>
      </>
    );
  }, [jobs, ctaHeadline, ctaTeaser, backPageUrl, activeTranslation]);

  return (
    <div className="container personio standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-small">{renderDetailsView()}</div>
    </div>
  );
};

StandardPersonio.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardPersonio;
