import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import PropTypes from "prop-types";

import Pagination from "react-js-pagination";
import Select, { components } from "react-select";

import TranslationContext from "../../../Context/Translation";
import ElementSwitcher from "../../ContentElements/ElementSwitcher";

import { getApi } from "../../../Utils/ApiUtils";

// import { ReactComponent as PaginationFirst } from "../../../Images/svg/pagination-first.svg";
// import { ReactComponent as PaginationLast } from "../../../Images/svg/pagination-last.svg";
import { ReactComponent as PaginationNext } from "../../../Images/svg/pagination-next.svg";
import { ReactComponent as PaginationPrevious } from "../../../Images/svg/pagination-previous.svg";

/**
 * one column layout full width
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NewsListLayout = ({ content, pageInfo }) => {
  const { translation } = useContext(TranslationContext);

  const settings = useRef({
    categories: [...content.colPos1.filter((el) => el.type === "news_pi1")[0].content.data.list],
    tags: [...content.colPos1.filter((el) => el.type === "news_pi1")[1].content.data.list],
    defaultCategory: [
      ...content.colPos1.filter((el) => el.type === "news_pi1")[0].content.data.list,
    ][0],
    defaultTag: [...content.colPos1.filter((el) => el.type === "news_pi1")[1].content.data.list][0],
    currentCategory: content.colPos1.filter((el) => el.type === "news_pi1")[0].content.data.settings
      .current,
    currentTag: content.colPos1.filter((el) => el.type === "news_pi1")[1].content.data.settings
      .current,
    pagination: {
      ...content.colPos0[0].content.data.pagination,
      itemsPerPage: content.colPos0[0].content.data.settings.PostsPerPage,
      resultCount: content.colPos0[0].content.data.itemTotalCount,
    },
  });

  const [news, setNews] = useState(content);
  const [category, setCategory] = useState(
    settings.current.currentCategory.minSlug || settings.current.defaultCategory.minSlug,
  );
  const [tag, setTag] = useState({
    label: settings.current.currentTag.title || settings.current.defaultTag.title,
    value: settings.current.currentTag.minSlug || settings.current.defaultTag.minSlug,
  });
  const [page, setPage] = useState(content.colPos0[0].content.data.pagination.current);

  const onCategorySelect = useCallback((e) => {
    e.preventDefault();

    // need to use CurrentTarget because of dropdowns (Target can be text sometimes, not link)
    const {
      currentTarget: { name },
    } = e;

    setCategory(name);
    setPage(1);
  }, []);

  const onTagSelect = useCallback((tagObj) => {
    setTag(tagObj);
    setPage(1);
  }, []);

  const onPageSelect = useCallback((currentPage) => {
    setPage(currentPage);
  }, []);

  const getUrl = useCallback(() => {
    const activeTranslation = translation.find((lang) => lang.active === 1).twoLetterIsoCode;

    const languageUrl = activeTranslation !== "de" ? activeTranslation : "";
    const baseUrl = content.colPos0[0].content.data.settings.baseSlug || "";
    const categoryUrl = category !== settings.current.defaultCategory.minSlug ? `/${category}` : "";
    const tagUrl = tag.value !== settings.current.defaultTag.minSlug ? `/${tag.value}` : "";
    const pageUrl = page !== 1 ? `/page-${page}` : "";

    return `${languageUrl}${baseUrl}${categoryUrl}${tagUrl}${pageUrl}`;
  }, [translation, category, tag, page, content.colPos0]);

  const loadNews = useCallback(async () => {
    try {
      const { data } = await getApi(getUrl());

      if (data.content?.colPos0?.length) {
        // have to change pagination data before re-render
        settings.current.pagination = {
          ...data.content.colPos0[0].content.data.pagination,
          itemsPerPage: data.content.colPos0[0].content.data.settings.PostsPerPage,
          resultCount: data.content.colPos0[0].content.data.itemTotalCount,
        };

        setNews(data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }, [getUrl]);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  useEffect(() => {
    const onMouseIn = (e) => {
      const toggler = e.target.querySelector(".dropdown-toggle");
      const menu = e.target.querySelector(".dropdown-menu");

      if (toggler && window.innerWidth > 1399) {
        if (menu && !menu.classList.contains("show")) {
          toggler.click();
        }
      }
    };

    const onMouseOut = (e) => {
      const toggler = e.target.querySelector(".dropdown-toggle");
      const menu = e.target.querySelector(".dropdown-menu");

      if (toggler && window.innerWidth > 1399) {
        if (menu && menu.classList.contains("show")) {
          toggler.click();
        }
      }
    };

    // hack with setTimeout, as without it querySelector child nodes are empty
    let navDropdowns;

    setTimeout(() => {
      navDropdowns = document.querySelectorAll(".categories .nav-item.dropdown");

      navDropdowns.forEach((el) => {
        el.addEventListener("mouseenter", onMouseIn);
        el.addEventListener("mouseleave", onMouseOut);
      });
    }, 250);

    return () => {
      navDropdowns.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseIn);
        el.removeEventListener("mouseleave", onMouseOut);
      });
    };
  }, [category]); // category has to be as a dependency to rerun on change

  const renderDropdownChild = useCallback(
    (child) => {
      return (
        <li key={`key_${child.id || Math.random()}`}>
          <a
            href="#"
            onClick={onCategorySelect}
            className={`dropdown-item ${category === child.minSlug ? "active" : ""}`}
            name={child.minSlug}
          >
            <span className="dropdown-text">{child.title}</span>
          </a>
        </li>
      );
    },
    [onCategorySelect, category],
  );

  const renderDropdown = useCallback(
    (categoryItem) => {
      return (
        <li className="nav-item dropdown" key={`key_${categoryItem.id || Math.random()}`}>
          <a
            href="#"
            onClick={onCategorySelect}
            className={`nav-link ${category === categoryItem.minSlug ? "active" : ""}`}
            name={categoryItem.minSlug}
          >
            {categoryItem.title}
          </a>

          <button
            id={categoryItem.slug}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="dropdown-toggle dropdown-toggle-split"
          >
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>

          <ul className="dropdown-menu" aria-labelledby={categoryItem.slug}>
            {categoryItem.children?.map((child) => {
              return renderDropdownChild(child);
            })}
          </ul>
        </li>
      );
    },
    [onCategorySelect, renderDropdownChild, category],
  );

  const renderCategoryList = useCallback(
    (categories) => {
      const parentCategories = categories.filter((categoryItem) => !categoryItem.parent);

      if (parentCategories.length > 0) {
        categories.forEach((categoryItem) => {
          const parentCategory = parentCategories.find(
            (parentCategoryItem) => parentCategoryItem.id === categoryItem.parent,
          );

          if (parentCategory) {
            if (parentCategory.children) {
              const hasDuplicate = parentCategory.children.find(
                (childCategoryItem) => childCategoryItem.id === categoryItem.id,
              );

              if (!hasDuplicate) {
                parentCategory.children.push(categoryItem);
              }
            } else {
              parentCategory.children = [];
            }
          }
        });
      }

      const processedCategories = parentCategories.length > 0 ? parentCategories : categories;

      return (
        categories.length > 1 && (
          <div key={`key_${categories.id || Math.random()}`} className="col-sm-12 col-lg-auto">
            {
              // .navbar hack to make the alignment of dropdown the same as in navigation
            }
            <ul className="navbar nav categories">
              {processedCategories.map((categoryItem) => {
                if (categoryItem.children?.length > 0) {
                  return renderDropdown(categoryItem);
                }

                return (
                  <li className="nav-item" key={`key_${categoryItem.title || Math.random()}`}>
                    <a
                      className={`nav-link ${category === categoryItem.minSlug ? "active" : ""}`}
                      href="#"
                      name={categoryItem.minSlug}
                      onClick={onCategorySelect}
                    >
                      {categoryItem.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )
      );
    },
    [category, onCategorySelect, renderDropdown],
  );

  const renderTagsList = useCallback(
    (tags) => {
      const styles = {
        indicatorSeparator: () => {},
        control: (base) => ({
          ...base,
          fontSize: 14,
          fontWeight: 700,
          color: "#515151",
          borderRadius: "24px",
          borderColor: "transparent",
          borderWidth: "2px",
          backgroundColor: "transparent",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          paddingTop: "0",
          paddingBottom: "0",
          boxShadow: "none",
          cursor: "pointer",
          "&:hover": {
            borderColor: "transparent",
          },
          "&:focus": {
            borderColor: "transparent",
          },
        }),
        singleValue: (base) => ({
          ...base,
          color: "#515151",
          fontWeight: 300,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 16,
          fontWeight: 400,
        }),
        option: (base, { isFocused, isSelected }) => ({
          ...base,
          cursor: "pointer",
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: isSelected ? "#b6272d" : isFocused ? "#b6272d" : "#ffffff",
          // eslint-disable-next-line no-nested-ternary
          color: isSelected ? "#ffffff" : isFocused ? "#ffffff" : "#505050",
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
          stroke: "#b6272d",
        }),
      };

      const options = tags.map((tagItem) => {
        const tagTitle = tagItem.title;

        return {
          label: tagTitle[0].toUpperCase() + tagTitle.slice(1),
          value: tagItem.minSlug,
        };
      });

      return (
        tags.length > 1 && (
          <div
            key={`key_${tags.id || Math.random()}`}
            className="col-sm-12 col-lg-auto news-list-tags"
          >
            <Select
              aria-label="Tag Select"
              options={options}
              styles={styles}
              onChange={onTagSelect}
              value={tag}
              components={{
                DropdownIndicator: (props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <components.DropdownIndicator {...props}>
                    <svg
                      id="SVGDoc"
                      width="16"
                      height="9"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="0 0 13 7"
                    >
                      <g>
                        <g>
                          <title>chevron-down</title>
                          <path
                            // eslint-disable-next-line max-len
                            d="M1.04003,0.61695c0.08061,-0.08081 0.19005,-0.12623 0.30419,-0.12623c0.11414,0 0.22358,0.04542 0.30419,0.12623l4.85157,4.85243v0l4.85157,-4.85243c0.168,-0.168 0.44038,-0.168 0.60838,0c0.168,0.168 0.168,0.44038 0,0.60838l-5.15577,5.15576c-0.08061,0.08081 -0.19005,0.12623 -0.30419,0.12623c-0.11414,0 -0.22358,-0.04542 -0.30419,-0.12623l-5.15577,-5.15577c-0.08081,-0.08061 -0.12623,-0.19005 -0.12623,-0.30419c0,-0.11414 0.04542,-0.22358 0.12623,-0.30419z"
                            fill="#b6272d"
                            fillOpacity="1"
                          />
                        </g>
                      </g>
                    </svg>
                  </components.DropdownIndicator>
                ),
              }}
            />
          </div>
        )
      );
    },
    [tag, onTagSelect],
  );

  const renderNewsNavigation = useCallback(() => {
    const newsList = news.colPos0.filter((el) => el.type === "news_pi1");
    let classes = "";

    if (newsList.length) {
      classes = newsList[0].appearance.bgColorClass;
    }

    return (
      <div className={classes}>
        <div className="container">
          <div className="row justify-content-between pb-medium pt-medium">
            {renderCategoryList(settings.current.categories)}

            {renderTagsList(settings.current.tags)}
          </div>
        </div>
      </div>
    );
  }, [renderCategoryList, renderTagsList, news.colPos0]);

  return (
    <>
      {news.colPos1.filter((el) => el.type !== "news_pi1").length > 0 && (
        <ElementSwitcher
          contentElements={news.colPos1.filter((el) => el.type !== "news_pi1")}
          breadcrumbs={pageInfo.breadcrumbs}
        />
      )}

      <div className="news-list">
        {news.colPos1 && renderNewsNavigation()}

        {news.colPos0 && (
          <ElementSwitcher
            contentElements={news.colPos0.filter((el) => el.type === "news_pi1")}
            breadcrumbs={pageInfo.breadcrumbs}
          />
        )}

        <div className="mt-small">
          {settings.current.pagination?.numberOfPages > 1 && (
            <div className="container pb-small">
              <div className="row">
                <div className="col-12">
                  <nav aria-label="News pagination">
                    <Pagination
                      innerClass="pagination justify-content-center"
                      itemClass="page-item"
                      itemClassFirst="page-item"
                      itemClassPrev="page-item"
                      itemClassNext="page-item"
                      itemClassLast="page-item"
                      linkClass="page-link"
                      linkClassFirst="page-link page-link-first"
                      linkClassPrev="page-link page-link-prev"
                      linkClassNext="page-link page-link-next"
                      linkClassLast="page-link page-link-last"
                      activePage={page}
                      itemsCountPerPage={settings.current.pagination.itemsPerPage}
                      totalItemsCount={settings.current.pagination.resultCount}
                      pageRangeDisplayed={5}
                      onChange={onPageSelect}
                      hideFirstLastPages={false}
                      // prevPageText={<> &lsaquo;</>}
                      // nextPageText={<>&rsaquo;</>}
                      // firstPageText="«"
                      // lastPageText="»"
                      prevPageText={<PaginationPrevious />}
                      nextPageText={<PaginationNext />}
                      firstPageText={null}
                      lastPageText={null}
                    />
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {news.colPos0.filter((el) => el.type !== "news_pi1").length > 0 && (
        <ElementSwitcher
          contentElements={news.colPos0.filter((el) => el.type !== "news_pi1")}
          breadcrumbs={pageInfo.breadcrumbs}
        />
      )}
    </>
  );
};

NewsListLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
  pageInfo: PropTypes.instanceOf(Object),
};

export default NewsListLayout;
