import React from "react";
import PropTypes from "prop-types";

import StandardScrollTransition from "./StandardScrollTransition";
import FullWidthScrollTransition from "./FullWidthScrollTransition";

import ComponentMissing from "../../Debug/ComponentMissing";

const ScrollTransitionSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  media,
  maxHeight,
  viewportHeight,
  mirrored,
  verticalAlignment,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    mirrored,
    verticalAlignment,
    animation,
  };

  function switchScrollTransition() {
    switch (layout) {
      case "standard":
        return <StandardScrollTransition properties={properties} />;
      case "full-width":
        return <FullWidthScrollTransition properties={properties} />;
      default:
        return <ComponentMissing type="ScrollTransition" subType={layout} />;
    }
  }

  return switchScrollTransition();
};

ScrollTransitionSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  media: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  maxHeight: PropTypes.string,
  viewportHeight: PropTypes.bool,
  mirrored: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  verticalAlignment: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default ScrollTransitionSwitcher;
