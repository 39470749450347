import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const HorizontalFactGrid = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    teaser,
    grid,
    columnsPerRow,
    cols,
    alignment,
    animation,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const renderFacts = useCallback(
    (facts) => {
      return facts.map((fact) => {
        return (
          <div
            key={`key_${fact.headline || Math.random()}`}
            className="fact-grid-item"
            data-aos={animation.factGreed.default}
          >
            <div className="card w-100">
              {(fact.image[0] || fact.headline) && (
                <div className="card-horizontal">
                  <div className="card-image">
                    {/* <Image image={fact.image[0]} animation={animation.image.factSheet} /> */}
                    <Image image={fact.image[0]} />
                  </div>

                  <div className={`${fact.image[0] ? "card-info ms-3" : "card-info"}`.trim()}>
                    <Headline headerType="h4" headline={fact.headline} />

                    {/* {fact.info && (
                      <div className="mt-xxxsmall"> */}
                    <Text textType="html" text={fact.info} />
                    {/* </div>
                    )} */}

                    {fact.link && (
                      // <div className="mt-xxsmall" data-aos={animation.links.default}>
                      <div className="mt-xxsmall">
                        <div className="links">
                          <LinkSwitcher linkType={fact.link_type} link={fact.link} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      });
    },
    [animation],
  );

  const renderGrid = useCallback(() => {
    return grid.map((gridColumn) => {
      return (
        <div key={`key_${gridColumn.header || Math.random()}`} className="col d-flex flex-column">
          {gridColumn.header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType="h4"
                headline={gridColumn.header}
                additionalClasses="fact-grid-column-heading"
              />
            </div>
          )}

          {gridColumn.facts.length > 0 && (
            <div className="mt-medium">{renderFacts(gridColumn.facts)}</div>
          )}
        </div>
      );
    });
  }, [grid, animation, renderFacts]);

  const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

  return (
    <div className="container fact-grid horizontal">
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}

          {teaser && (
            <div className="mt-xsmall" data-aos={animation.teaser.default}>
              <Text textType="html" text={teaser} />
            </div>
          )}

          {grid.length > 0 && (
            <div className="mt-small">
              <div
                className={`row 
                          row-cols-${colXs} 
                          row-cols-sm-${colSm} 
                          row-cols-md-${colMd} 
                          row-cols-lg-${colLg} 
                          g-5`.replace(/\n\s+/g, "")}
              >
                {renderGrid()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HorizontalFactGrid.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default HorizontalFactGrid;
