import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import * as am4core from "@amcharts/amcharts4/core";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

import AOS from "aos";
import "aos/dist/aos.css";

import { ParallaxProvider } from "react-scroll-parallax";
import { TranslationProvider } from "../Context/Translation";
import LayoutContainer from "./LayoutContainer";
import PageContainer from "./PageContainer";
// import PageMissing from "../Components/Debug/PageMissing";
import SearchContainer from "./SearchContainer";

import {
  REACT_APP_AM_CHARTS_LICENCE,
  REACT_APP_AM_CHARTS_MAPS_LICENCE,
} from "../Constants/ChartConstants";

import { PAGE } from "../Constants/RouteConstants";

am4core.addLicense(REACT_APP_AM_CHARTS_LICENCE);
am4core.addLicense(REACT_APP_AM_CHARTS_MAPS_LICENCE);

am4core.useTheme(am4themesAnimated);

AOS.init({
  once: true,
  startEvent: "load",
  // disable: "mobile",
  offset: 100,
  duration: 750,
  delay: 250,
  easing: "ease-out",
  anchorPlacement: "top-bottom",
});

/* eslint-disable react/jsx-props-no-spreading */
const RootContainer = () => {
  return (
    <BrowserRouter>
      <TranslationProvider>
        <ParallaxProvider>
          <LayoutContainer>
            {/* <section id="main" className="pt-xlarge"> */}
            <section id="main">
              <Switch>
                {/* <Route exact path="/404" component={PageMissing} /> */}
                <Route
                  exact
                  path="/s"
                  render={(routerProps) => (
                    <SearchContainer
                      {...routerProps}
                      key={`key_${routerProps.location.search || Math.random()}`}
                    />
                  )}
                />
                <Route
                  path={PAGE}
                  render={(routerProps) => (
                    <PageContainer
                      {...routerProps}
                      key={`key_${routerProps.location.pathname || Math.random()}`}
                    />
                  )}
                />
              </Switch>
            </section>
          </LayoutContainer>
        </ParallaxProvider>
      </TranslationProvider>
    </BrowserRouter>
  );
};

export default RootContainer;
