import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { Parallax } from "react-scroll-parallax";
import { Carousel } from "bootstrap";
/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
import videojs from "video.js";
import VREPlayer from "videojs-react-enhanced";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Image from "../../Atoms/Image";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import "video.js/dist/video-js.css";

import { isMobile } from "../../../Utils/FunctionalUtils";

// import { ReactComponent as ScrolToNextSection } from "../../../Images/svg/arrow-scroll-down.svg";

const SlideshowStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    slides,
    maxHeight,
    viewportHeight,
    verticalAlignment,
    animation,
  },
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const carouselRef = useRef(null);

  const players = useRef([]);

  // const scrollToNext = useRef(null);

  // const onScrollToNextClick = useCallback(() => {
  //   if (scrollToNext.current) {
  //     const parentSection = scrollToNext.current.closest("section");
  //     const navigation = document.querySelector("#navigation .navbar");

  //     if (parentSection) {
  //       const nextSection = parentSection.nextElementSibling;
  //       const nextSectionTop =
  //         nextSection.getBoundingClientRect().top + window.pageYOffset - navigation.offsetHeight;

  //       if (nextSection) {
  //         window.scrollTo({ top: nextSectionTop, behavior: "smooth" });
  //       }
  //     }
  //   }
  // }, [scrollToNext]);

  const renderCarouselItems = useCallback(() => {
    if (slides.length === 1) {
      if (slides[0].properties.type === "video") {
        const playerOptions = {
          src: slides[0]?.publicUrl || "",
          controls: false,
          autoplay: true,
          playsinline: true,
          muted: true,
        };

        const videojsOptions = {
          fluid: false,
          loop: true,
        };

        return (
          <div
            className="carousel-item carousel-item-no-animation carousel-item-video"
            data-player="0"
          >
            <VREPlayer
              playerOptions={playerOptions}
              videojsOptions={videojsOptions}
              onReady={(player) => {
                player.playsinline(true);

                const targetSlide = document.querySelector(
                  `.carousel-item-video[data-player="${0}"]`,
                );

                targetSlide.dataset.player = player.id();

                players.current.push(player);
              }}
              onEnded={() => {
                const carousel = Carousel.getInstance(carouselRef.current);

                carousel.next();
                carousel.cycle();
              }}
            />
          </div>
        );
      }
      return (
        <div className="carousel-item carousel-item-no-animation">
          <Image image={slides[0]} additionalClasses="d-block w-100" />
        </div>
      );
    }

    if (slides.length > 1) {
      return slides.map((slide, index) => {
        if (slide.properties.type === "video") {
          const playerOptions = {
            src: slide?.publicUrl || "",
            controls: false,
            autoplay: false,
            playsinline: true,
            muted: true,
          };

          const videojsOptions = {
            fluid: false,
            loop: false,
          };

          return (
            <div
              key={`key_${index || Math.random()}`}
              className="carousel-item carousel-item-no-animation carousel-item-video"
              data-player={index}
            >
              <VREPlayer
                playerOptions={playerOptions}
                videojsOptions={videojsOptions}
                onReady={(player) => {
                  player.playsinline(true);

                  const targetSlide = document.querySelector(
                    `.carousel-item-video[data-player="${index}"]`,
                  );

                  targetSlide.dataset.player = player.id();

                  players.current.push(player);
                }}
                onEnded={() => {
                  const carousel = Carousel.getInstance(carouselRef.current);

                  carousel.next();
                  carousel.cycle();
                }}
              />
            </div>
          );
        }
        return (
          <div key={`key_${index || Math.random()}`} className="carousel-item">
            <Image image={slide} additionalClasses="d-block w-100" />
          </div>
        );
      });
    }

    return null;
  }, [slides]);

  useEffect(() => {
    const setDisabledHandler = () => {
      if (isMobile()) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    };

    setDisabledHandler();

    setTimeout(() => {
      // eslint-disable-next-line no-unused-vars
      const carousel = Carousel.getOrCreateInstance(carouselRef.current, {
        ride: "carousel",
        interval: 3000,
      });
      carouselRef.current.querySelector(".carousel-item:first-child").classList.add("active");
      carouselRef.current.classList.remove("carousel-uninit");

      if (
        carouselRef.current
          .querySelector(".carousel-item:first-child")
          .classList.contains("carousel-item-video")
      ) {
        players.current
          .find(
            (player) =>
              player.id() ===
              carouselRef.current.querySelector(".carousel-item:first-child").dataset.player,
          )
          .play();
      } else {
        carousel.cycle();
      }

      carouselRef.current.addEventListener("slide.bs.carousel", function (e) {
        const nextSlide = e.relatedTarget;

        if (nextSlide.classList.contains("carousel-item-video")) {
          // does not work without setTimeout()
          setTimeout(() => {
            carousel.pause();
          }, 250);

          players.current.find((player) => player.id() === nextSlide.dataset.player).play();
        }
      });
    }, 250);

    window.addEventListener("resize", setDisabledHandler);
  }, []);

  const getClasses = useCallback(() => {
    let classes = "row justify-content-center text-start";

    switch (verticalAlignment) {
      case "top":
        classes += " align-items-start";
        break;
      case "center":
        classes += " align-items-center";
        break;
      case "bottom":
        classes += " align-items-end";
        break;
      default:
        classes += " align-items-center";
    }

    return classes;
  }, [verticalAlignment]);

  return (
    <div
      className={`stage slideshow ${
        viewportHeight ? "min-vh-100 pt-xlarge pb-xlarge" : "pt-xlarge pb-xlarge"
      }`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || slides[0]?.properties.dimensions.height } : {}
      }
    >
      <div ref={carouselRef} className="carousel slide carousel-fade carousel-uninit">
        <div className="carousel-inner">{renderCarouselItems()}</div>
      </div>

      <div className="container">
        <div className={getClasses()}>
          <div className="col-md-12">
            <Parallax y={[25, -50]} disabled={isDisabled}>
              {subline && (
                <div data-aos={animation.preline.default}>
                  <Preline preline={subline} />
                </div>
              )}

              {header && (
                <div data-aos={animation.headline.stage.video}>
                  <Headline
                    headerType={headerType}
                    headerStyle={headerDisplayStyle}
                    headline={header}
                  />
                </div>
              )}

              {teaser && (
                <div className="mt-medium" data-aos={animation.teaser.default}>
                  <Text textType="html" text={teaser} />
                </div>
              )}

              {(headerLink || secondaryLink) && (
                <div className="mt-small" data-aos={animation.links.default}>
                  <div className="links">
                    <LinkSwitcher
                      linkType={headerLinkType}
                      link={headerLink}
                      // additionalClasses="btn-lg rounded-pill"
                    />

                    <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                  </div>
                </div>
              )}
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  );
};

SlideshowStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SlideshowStage;
