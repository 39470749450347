import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const SliderNewsListitem = ({ news, linkType, linkTitle, hideDate, animation }) => {
  const inlineStyles = {
    backgroundImage: `url(${news.media?.[0]?.images.listViewFeaturedImage.publicUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: "75%",
  };

  return (
    <div className="col d-flex" data-aos={animation.newsList.default}>
      <div className="card w-100">
        <div className="card-image">
          <Image imageType="background" image={news.media?.[0]} style={inlineStyles} />
        </div>

        <div className="card-body">
          {!hideDate && <Preline preline={news.datetime} additionalClasses="color-static" />}

          {news.slug &&
            (news.slug.includes("http") ? (
              <a href={news.slug}>
                <Headline headerType="h5" headline={news.title} additionalClasses="color-static" />
              </a>
            ) : (
              <NavLink to={news.slug}>
                <Headline headerType="h5" headline={news.title} additionalClasses="color-static" />
              </NavLink>
            ))}

          {news.teaser && (
            <div className="mt-xsmall">
              <Text
                textType="text"
                text={`${news.teaser.slice(0, 350)}...`}
                additionalClasses="color-static"
              />
            </div>
          )}

          {news.slug && (
            // <div className="mt-small" data-aos={animation.links.default}>
            <div className="mt-small">
              <div className="links">
                <LinkSwitcher
                  linkType={linkType}
                  link={{ publicUrl: news.slug, title: linkTitle }}
                  additionalClasses="color-static"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SliderNewsListitem.propTypes = {
  news: PropTypes.instanceOf(Object),
  linkType: PropTypes.string,
  linkTitle: PropTypes.string,
  hideDate: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default SliderNewsListitem;
