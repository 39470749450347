import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const StandardPersonioList = ({ filteredJobs, translations }) => {
  return Object.keys(filteredJobs).map((category, index) => {
    return (
      <div
        key={`key_${category}`}
        className={`personio-list-jobs-wrapper ${index === 0 ? "mt-medium" : ""}`.trim()}
      >
        <h3 className="personio-list-jobs-category d-none">{category}</h3>

        <ul className="personio-list-jobs">
          {filteredJobs[category].map((job) => {
            return (
              <li key={`key_${job.id}`} className="personio-list-jobs-item">
                <NavLink to={job.slug.split("?")[0]}>
                  <span> {job.name} </span>

                  {translations.jobDetails}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    );
  });
};

StandardPersonioList.propTypes = {
  filteredJobs: PropTypes.instanceOf(Object),
  // setSelectedJob: PropTypes.func,
  translations: PropTypes.instanceOf(Object),
};

export default StandardPersonioList;
