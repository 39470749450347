import React from "react";
import PropTypes from "prop-types";

import StandardPersonioDetails from "./StandardPersonioDetails";
import ComponentMissing from "../../Debug/ComponentMissing";

const PersonioDetails = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  jobs,
  // companies,
  // categories,
  ctaHeadline,
  ctaTeaser,
  backPageUrl,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    jobs,
    // companies,
    // categories,
    ctaHeadline,
    ctaTeaser,
    backPageUrl,
    animation,
  };

  function switchPersonioDetails() {
    switch (layout) {
      case "standard":
        return <StandardPersonioDetails properties={properties} />;
      default:
        return <ComponentMissing type="PersonioDetails" subType={layout} />;
    }
  }

  return switchPersonioDetails();
};

PersonioDetails.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  jobs: PropTypes.instanceOf(Array),
  // companies: PropTypes.instanceOf(Array),
  // categories: PropTypes.instanceOf(Array),
  ctaHeadline: PropTypes.string,
  ctaTeaser: PropTypes.string,
  backPageUrl: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default PersonioDetails;
