import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const ButtonLink = ({ properties: { linkType, link, additionalClasses, onClick } }) => {
  const getClasses = useCallback(() => {
    let appearance = "btn";

    switch (linkType) {
      case "btn-primary":
        appearance += " btn-primary";
        break;
      case "btn-outline-primary":
        appearance += " btn-outline-primary";
        break;
      case "btn-secondary":
        appearance += " btn-secondary";
        break;
      case "btn-outline-secondary":
        appearance += " btn-outline-secondary";
        break;
      case "btn-light":
        appearance += " btn-light";
        break;
      case "btn-outline-light":
        appearance += " btn-outline-light";
        break;
      case "btn-dark":
        appearance += " btn-dark";
        break;
      case "btn-outline-dark":
        appearance += " btn-outline-dark";
        break;
      default:
        appearance += "";
    }

    return appearance;
  }, [linkType]);

  const getButtonLink = useCallback(() => {
    if (link.publicUrl?.includes("http") || link.url?.includes("http")) {
      if (link.publicUrl?.includes("fileadmin") || link.url?.includes("fileadmin")) {
        return (
          <a
            href={
              link.properties?.originalUrl ||
              link?.originalUrl ||
              link.publicUrl?.split("/")[1] ||
              link.url?.split("/")[1]
            }
            target={link.target}
            data-sectionid={link.sectionId}
            data-isin={link.isin}
            className={`${getClasses()} ${additionalClasses}`.trim()}
            onClick={onClick}
          >
            {link.title}
          </a>
        );
      }

      return (
        <a
          href={link?.originalUrl || link.publicUrl || link.url}
          target={link.target}
          data-sectionid={link.sectionId}
          data-isin={link.isin}
          className={`${getClasses()} ${additionalClasses}`.trim()}
          onClick={onClick}
        >
          {link.title}
        </a>
      );
    }

    if (
      link.publicUrl?.includes("http") ||
      link.url?.includes("http") ||
      link.publicUrl?.includes("mailto") ||
      link.url?.includes("mailto") ||
      link.publicUrl?.includes("tel") ||
      link.url?.includes("tel")
    ) {
      return (
        <a
          href={
            link.publicUrl?.replace(/\/landing\/?/, "/") || link.url?.replace(/\/landing\/?/, "/")
          }
          target={link.target}
          data-sectionid={link.sectionId}
          data-isin={link.isin}
          className={`${getClasses()} ${additionalClasses}`.trim()}
          onClick={onClick}
        >
          {link.title}
        </a>
      );
    }

    return (
      <NavLink
        to={link.publicUrl || link.url}
        target={link.target}
        data-sectionid={link.sectionId}
        data-isin={link.isin}
        className={`${getClasses()} ${additionalClasses}`.trim()}
        activeClassName=""
        onClick={onClick}
        // aria-current="false"
      >
        {link.title}
      </NavLink>
    );
  }, [link, additionalClasses, getClasses, onClick]);

  return link && (link.publicUrl || link.url) ? getButtonLink() : <></>;
};

ButtonLink.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default ButtonLink;
