import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import SlickSlider from "react-slick";

import { Parallax } from "react-scroll-parallax";

import { isMobile } from "../../../Utils/FunctionalUtils";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";

const PrevArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-prev-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

const NextArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-next-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SecondaryQuotes = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    quoteItems,
    mirrored,
    animation,
  },
}) => {
  const sliderRef = useRef(null);

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  });

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const setDisabledHandler = () => {
      if (isMobile()) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    };

    setDisabledHandler();

    window.addEventListener("resize", setDisabledHandler);
  }, []);

  const renderQuotes = useCallback(() => {
    return quoteItems.map((quote) => {
      return (
        <div
          key={`key_${quote.name || Math.random()}`}
          className="slider-item d-flex justify-content-center"
        >
          <div className="card w-100">
            <div className="card-body">
              <figure className="quotes-text">
                <Parallax y={[-15, 15]} disabled={isDisabled}>
                  <blockquote data-aos={animation.teaser.default}>
                    <Text textType="text" text={quote.quote} />
                  </blockquote>

                  <figcaption data-aos={animation.headline.quote}>
                    {quote.name && (
                      <div className="mt-xsmall">
                        <Preline preline={quote.title} />
                      </div>
                    )}

                    <Headline headerType="h5" headline={quote.name} />
                  </figcaption>
                </Parallax>
              </figure>
            </div>

            <div className="card-image">
              <Image image={quote.image[0]} animation={animation.image.default} />
            </div>
          </div>
        </div>
      );
    });
  }, [quoteItems, animation, isDisabled]);

  return (
    <div
      // eslint-disable-next-line max-len
      // className={`container quotes secondary min-vh-100 d-flex flex-column justify-content-center ${
      className={`container quotes secondary  ${
        mirrored && mirrored !== "0" ? "mirrored" : ""
      }`.trim()}
    >
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {quoteItems.length > 0 && (
        <div className="row justify-content-center mt-small">
          <div className="col-xl-10">
            <div ref={sliderRef} id={`quotes-secondary-${id}`}>
              <SlickSlider {...settings.current}>{renderQuotes()}</SlickSlider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SecondaryQuotes.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SecondaryQuotes;
