import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Parallax } from "react-scroll-parallax";

// eslint-disable-next-line no-unused-vars
import videojs from "video.js";
import VREPlayer from "videojs-react-enhanced";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import "video.js/dist/video-js.css";

import { isMobile } from "../../../Utils/FunctionalUtils";

const VideoStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    verticalAlignment,
    animation,
  },
}) => {
  const playerOptions = {
    src: media[0]?.publicUrl || "",
    controls: false,
    autoplay: "muted",
    playsinline: true,
  };

  const videojsOptions = {
    fluid: false,
    loop: true,
  };

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const setDisabledHandler = () => {
      if (isMobile()) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    };

    setDisabledHandler();

    window.addEventListener("resize", setDisabledHandler);
  }, []);

  const getClasses = useCallback(() => {
    let classes = "row justify-content-center text-start";

    switch (verticalAlignment) {
      case "top":
        classes += " align-items-start";
        break;
      case "center":
        classes += " align-items-center";
        break;
      case "bottom":
        classes += " align-items-end";
        break;
      default:
        classes += " align-items-center";
    }

    return classes;
  }, [verticalAlignment]);

  return (
    <div
      className={`stage video ${
        viewportHeight ? "min-vh-100 pt-xlarge pb-xlarge" : "pt-xlarge pb-xlarge"
      }`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || media[0]?.properties.dimensions.height } : {}
      }
    >
      <VREPlayer
        playerOptions={playerOptions}
        videojsOptions={videojsOptions}
        onReady={(player) => {
          player.playsinline(true);
        }}
      />

      <div className="container">
        <div className={getClasses()}>
          <div className="col-md-12">
            <Parallax y={[25, -50]} disabled={isDisabled}>
              {subline && (
                <div data-aos={animation.preline.default}>
                  <Preline preline={subline} />
                </div>
              )}

              {header && (
                <div data-aos={animation.headline.stage.video}>
                  <Headline
                    headerType={headerType}
                    headerStyle={headerDisplayStyle}
                    headline={header}
                  />
                </div>
              )}

              {teaser && (
                <div className="mt-medium" data-aos={animation.teaser.default}>
                  <Text textType="html" text={teaser} />
                </div>
              )}

              {(headerLink || secondaryLink) && (
                <div className="mt-small" data-aos={animation.links.default}>
                  <div className="links">
                    <LinkSwitcher
                      linkType={headerLinkType}
                      link={headerLink}
                      // additionalClasses="btn-lg rounded-pill"
                    />

                    <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                  </div>
                </div>
              )}
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default VideoStage;
