export const mapWrapperClasses = () => {
  return "container";
};

export const plaeholder = () => {
  return "placeholder";
};

export const scrollToAnchor = () => {
  // scroll to anchor if exists
  const { hash } = window.location;

  if (hash !== "") {
    // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
    const decodedHash = window.decodeURIComponent(hash).split("=")[0];

    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = decodedHash.replace("#", "");
      const element = document.getElementById(id);

      // if (element) element.scrollIntoView({ behavior: "smooth" });
      if (element) {
        let elementOffset = element.offsetTop;
        const navigationElement = document.getElementById("navigation").querySelector(".navbar");
        const navigationHeight = navigationElement.offsetHeight;

        if (element.id === "verfahrenstechnik_von_iba_814" && window.innerWidth > 991) {
          elementOffset += window.outerHeight / 4;
        }

        window.scroll({ top: elementOffset - navigationHeight, left: 0, behavior: "smooth" });
      }
    }, 250);
  }
};

export const getAnimation = (animationLayout, mirrored = "0") => {
  switch (animationLayout) {
    case "type1":
      return {
        image: {
          default: +mirrored ? "fade-right" : "fade-left",
          contactCard: "fade-up-right",
          factSheet: "fade-up",
          teaserAndImage: +mirrored ? "fade-left" : "fade-right",
          stage: "none",
        },
        preline: {
          default: "fade-in",
        },
        headline: {
          default: "fade-in",
          stage: {
            video: "fade-in",
            centered: "fade-in",
            promotion: "fade-in",
            equal: "fade-in",
          },
          quote: "fade-up",
        },
        teaser: {
          default: "fade-up",
        },
        links: {
          default: "fade-up",
        },
        list: {
          default: "fade-up",
        },
        googleMap: {
          default: true,
        },
        factGreed: {
          default: "fade-up",
        },
        factSheet: {
          default: "fade-up",
        },
        features: {
          default: "fade-up",
          secondary: "fade-up",
        },
        gridGallery: {
          default: "fade-up",
        },
        onlyImage: {
          default: "fade-up",
        },
        newsList: {
          default: "fade-up",
        },
        downloads: {
          default: "fade-up",
        },
        accordion: {
          default: "fade-up",
        },
      };
    case "type2":
      return {
        image: {
          // default: +mirrored ? "fade-up-right" : "fade-up-left",
          default: "zoom-in-up",
          contactCard: "fade-down-right",
          factSheet: "fade-down",
          teaserAndImage: +mirrored ? "fade-left" : "fade-right",
          stage: "none",
        },
        preline: {
          default: "fade-in",
        },
        headline: {
          default: "fade-in",
          stage: {
            video: "fade-in",
            centered: "fade-in",
            promotion: "fade-in",
            equal: "fade-in",
          },
          quote: "fade-up",
        },
        teaser: {
          default: "fade-up",
        },
        links: {
          default: "fade-up",
        },
        list: {
          default: "fade-up",
        },
        googleMap: {
          default: true,
        },
        factGreed: {
          default: "fade-up",
        },
        factSheet: {
          default: "fade-up",
        },
        features: {
          default: "fade-up",
          secondary: "zoom-in-up",
        },
        gridGallery: {
          default: "fade-up",
        },
        onlyImage: {
          default: "fade-up",
        },
        newsList: {
          default: "fade-up",
        },
        downloads: {
          default: "fade-up",
        },
        accordion: {
          default: "fade-up",
        },
      };
    default:
      return {
        image: {
          default: "none",
          contactCard: "none",
          factSheet: "none",
          teaserAndImage: "none",
          stage: "none",
        },
        preline: {
          default: "none",
        },
        headline: {
          default: "none",
          stage: {
            video: "none",
            centered: "none",
            promotion: "none",
            equal: "none",
          },
          quote: "none",
        },
        teaser: {
          default: "none",
        },
        links: {
          default: "none",
        },
        list: {
          default: "none",
        },
        googleMap: {
          default: false,
        },
        factGreed: {
          default: "none",
        },
        factSheet: {
          default: "none",
        },
        features: {
          default: "none",
          secondary: "none",
        },
        gridGallery: {
          default: "none",
        },
        onlyImage: {
          default: "none",
        },
        newsList: {
          default: "none",
        },
        downloads: {
          default: "none",
        },
        accordion: {
          default: "none",
        },
      };
  }
};
