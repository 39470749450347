import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
// import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

// import Fancybox from "../../Atoms/FancyBox";

// import { REACT_APP_API_BASE_URL } from "../../../Constants/APIConstants";

const WallGridGallery = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    // id,
    galleryItems,
    columnsPerRow,
    // lightbox,
    animation,
  },
}) => {
  const renderGallery = useCallback(() => {
    const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

    return (
      <div
        className={`row 
                    row-cols-${colXs} 
                    row-cols-sm-${colSm} 
                    row-cols-md-${colMd} 
                    row-cols-lg-${colLg} 
                    justify-content-center 
                    g-4`.replace(/\n\s+/g, "")}
      >
        {galleryItems.map((galleryItem) => {
          const backgroundimage = galleryItem.background?.[0] || {};
          const logo = galleryItem.logo?.[0] || {};

          return (
            <a
              key={galleryItem.cardLink.url || Math.random()}
              href={galleryItem.cardLink.url}
              // target={galleryItem.cardLink.target || "_blank"}
              target={galleryItem.cardLink.target || null}
              rel="noreferrer"
              className="d-flex"
              data-aos={animation.gridGallery.default}
            >
              <div className="card w-100">
                <div className="card-background">
                  <Image image={backgroundimage} />
                </div>

                <div className="card-image">
                  <Image image={logo} />
                </div>

                <div className="card-info">
                  <Headline
                    headerType="h3"
                    headline={galleryItem.header}
                    additionalClasses="color-static"
                  />

                  {galleryItem.info && (
                    <div className="mt-xxxsmall">
                      <Text
                        textType="html"
                        text={galleryItem.info}
                        additionalClasses="color-static"
                      />
                    </div>
                  )}
                </div>
              </div>
            </a>
          );
        })}
      </div>
    );
    // }, [galleryItems, columnsPerRow, lightbox, id, animation]);
  }, [galleryItems, columnsPerRow, animation]);

  return (
    <div className="overflow-hidden grid-gallery wall">
      <div className="row justify-content-center">
        <div className="col-md-10">
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}
        </div>
      </div>

      {galleryItems.length > 0 && (
        <div className="row mt-small">
          <div className="col-12">{renderGallery()}</div>
        </div>
      )}
    </div>
  );
};

WallGridGallery.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default WallGridGallery;
