import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Image from "../../Atoms/Image";
import Text from "../../Atoms/Text";

const SecondaryTAILItem = ({ headline, teaser, image, index, length }) => {
  return (
    <div className={`row ${index !== length - 1 ? "mb-small" : ""}`.trim()}>
      <div className="col-3 col-sm-2 col-lg-3 mb-xxsmall mb-lg-0 text-start text-lg-end">
        <Image image={image} />
      </div>

      <div className="col-9 col-sm-10 col-lg-9 d-flex flex-column justify-content-center">
        <Headline headerType="h5" headline={headline} />

        <Text textType="html" text={teaser} />
      </div>
    </div>
  );
};

SecondaryTAILItem.propTypes = {
  headline: PropTypes.string,
  teaser: PropTypes.string,
  image: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  length: PropTypes.number,
};

export default SecondaryTAILItem;
